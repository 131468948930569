import Image from 'next/image';
import { FC } from 'react';

interface Props {
  facility: CSVFacility;
}

export const FaciltyCardImage: FC<Props> = ({ facility }) => {
  const mainSport = facility?.mainSport as Sport;

  if (facility.mainImage) {
    return (
      <Image
        className="w-full h-[200px] object-cover rounded-[20px]"
        src={facility.mainImage}
        alt={facility.name}
        width={296}
        height={200}
      />
    );
  }

  if (mainSport == 'other') {
    return (
      <Image
        className="w-full h-[200px] object-cover rounded-[20px]"
        src={`/images/facility-fallbacks/fitness.webp`}
        alt="Facility image"
        width={296}
        height={200}
      />
    );
  }

  if (mainSport) {
    return (
      <Image
        className="w-full h-[200px] object-cover rounded-[20px]"
        src={`/images/facility-fallbacks/${mainSport}.webp`}
        alt="Facility image"
        width={296}
        height={200}
      />
    );
  }
};
