import { atom } from 'recoil';
import { FILTERS } from '../const';
import { RequestedFacility } from '../models';

const requestedFacilities = atom<RequestedFacility[]>({
  key: 'direactory.requestedFacilities',
  default: [],
});

const directoryFilter = atom({
  key: 'directory.filter',
  default: FILTERS[0],
});

export const directoryState = {
  requestedFacilities,
  directoryFilter,
};
