import Shooting from 'public/icons/sports/airsoft.svg';
import Basketball from 'public/icons/sports/basketball.svg';
import Football from 'public/icons/sports/football.svg';
import Golf from 'public/icons/sports/golf.svg';
import Gym from 'public/icons/sports/gym.svg';
import Gymnastics from 'public/icons/sports/gymnastics.svg';
import Kickboxing from 'public/icons/sports/kickboxing.svg';
import Paintball from 'public/icons/sports/paintball.svg';
import Squash from 'public/icons/sports/squash.svg';
import {
  default as TableTennis,
  default as Tennis,
} from 'public/icons/sports/tennis.svg';
import Volleyball from 'public/icons/sports/volleyball.svg';

export const FILTERS = [
  {
    nameId: 'directory.all',
    sport: null,
    icon: null,
  },
  {
    nameId: 'directory.football',
    sport: 'football',
    icon: (
      <Football
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>g]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.basketball',
    sport: 'basketball',
    icon: (
      <Basketball
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.gym',
    sport: 'fitness',
    icon: (
      <Gym
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.kickboxing',
    sport: 'kickboxing',
    icon: (
      <Kickboxing
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.paintball',
    sport: 'paintball',
    icon: (
      <Paintball
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.volleyball',
    sport: 'volleyball',
    icon: (
      <Volleyball
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.gymnastics',
    sport: 'gymnastics',
    icon: (
      <Gymnastics
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.tennis',
    sport: 'tennis',
    icon: (
      <Tennis
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.shooting',
    sport: 'shooting',
    icon: (
      <Shooting
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.tableTennis',
    sport: 'tableTennis',
    icon: (
      <TableTennis
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.golf',
    sport: 'golf',
    icon: (
      <Golf
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.squash',
    sport: 'squash',
    icon: (
      <Squash
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.airsoft',
    sport: 'airsoft',
    icon: (
      <Paintball
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>path]:fill-black"
      />
    ),
  },
  {
    nameId: 'directory.bubbleFootball',
    sport: 'bubbleFootball',
    icon: (
      <Football
        viewBox="0 0 24 24"
        className="h-16 w-16 group-hover:[&>g]:fill-black"
      />
    ),
  },
];
