import { FirebaseService } from '@modules/firebase';
import { toKebabCase } from '@shared/utils';
import { doc, getDoc } from 'firebase/firestore';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

export function useGetCsvFacilities() {
  const [facilities, setFacilities] = useState<CSVFacility[]>([]);
  const [firebaseFacilites, setFirebaseFacilites] = useState<Facility[]>([]);
  const [facility, setFacility] = useState<CSVFacility | null>(null);
  const [firebaseFacility, setFirebaseFacility] = useState<Facility | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const pathname = usePathname();

  const getName = (text: string) => {
    const lastIndex = text.lastIndexOf('/');
    return text.substring(lastIndex + 1);
  };

  async function getData(pathname: string) {
    const name = getName(decodeURIComponent(pathname));

    try {
      const data = await fetch('/osijek.json');
      const facilities: CSVFacility[] = await data.json();
      setFacilities(facilities);

      if (!name || name === 'directory') return;

      const facility = facilities.find(
        (facility) => toKebabCase(facility.name) === name,
      );

      if (facility?.firebaseFacilityId) {
        let firebaseFacility = null;
        const facilityReference = doc(
          FirebaseService.firestore,
          'facilities',
          facility.firebaseFacilityId,
        );
        const facilitySnapshot = await getDoc(facilityReference);

        if (facilitySnapshot.exists()) {
          firebaseFacility = {
            ...facilitySnapshot.data(),
            id: facilitySnapshot.id,
          };
        }
        setFirebaseFacility(firebaseFacility);
      }

      setFacility(facility);
    } catch (e) {
      toast.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData(pathname);
  }, [pathname]);

  return {
    facilities,
    firebaseFacility,
    facility,
    loading,
  };
}
